import React, { createContext, useReducer } from "react";
import AppReducer from "./AppReducer";

export const GlobalActionType = {
  SET_AUTH: "SET_AUTH",
  SIGN_OUT: "SIGN_OUT",
  SET_LOADER: "SET_LOADER",
  SET_REPORTS: "SET_REPORTS",
};

const initialState = {
  auth: null,
  loader: { isLoading: true, text: undefined },
  reports: null,
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  function setAuth(data) {
    dispatch({
      type: GlobalActionType.SET_AUTH,
      payload: data,
    });
  }

  function setLoader(data) {
    dispatch({
      type: GlobalActionType.SET_LOADER,
      payload: data,
    });
  }

  function setReports(data) {
    dispatch({
      type: GlobalActionType.SET_REPORTS,
      payload: data,
    });
  }

  function signOut() {
    dispatch({
      type: GlobalActionType.SIGN_OUT,
      payload: null,
    });
  }

  return (
    <GlobalContext.Provider value={{ ...state, setAuth, setLoader, setReports, signOut }}>
      {children}
    </GlobalContext.Provider>
  );
};
