/*eslint-disable*/
import React from "react";
// reactstrap components
import { Button, NavItem, NavLink, Nav, Container, Row, Col, UncontrolledTooltip } from "reactstrap";

class SimpleFooter extends React.Component {
  render() {
    return (
      <>
        <div className="mt--50">
          <svg className="wave footer-wave" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <defs>
              <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#34c2ef " />
                <stop offset="100%" stopColor="#349BEF" />
              </linearGradient>
            </defs>
            <path
              fillOpacity="1"
              d="M0,288L48,277.3C96,267,192,245,288,245.3C384,245,480,267,576,272C672,277,768,267,864,229.3C960,192,1056,128,1152,96C1248,64,1344,64,1392,64L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
            ></path>
          </svg>
        </div>
        <footer className="footer bg-gradient-footer position-relative">
          <Container className="position-relative bottom-0">
            <Row className="row-grid text-center">
              <Col lg="6">
                <img alt="..." src={require("assets/img/brand/Logo_Life Labs_alb-01.svg")} height={42} />

                <div className="pl-2 mt-2">
                  <a
                    href="https://firebasestorage.googleapis.com/v0/b/labs-scanner.appspot.com/o/legal%2FTERMENI%20SI%20CONDITII.pdf?alt=media&token=2ecbcbce-d5ee-4dad-b3ad-2896159b0cbb"
                    className="text-white"
                  >
                    <b>Termeni si conditii</b>
                  </a>
                </div>

                <div className="pl-2 mt-2">
                  <a
                    href="https://firebasestorage.googleapis.com/v0/b/labs-scanner.appspot.com/o/legal%2FPolitica%20de%20confidentialitate.pdf?alt=media&token=db364d66-56e7-4cfb-8a14-71ef972698c5"
                    className="text-white"
                  >
                    <b>Politica de confidentialitate</b>
                  </a>
                </div>
              </Col>
              <Col className="btn-wrapper" lg="6">
                <h4 className="text-white font-weight-600">CONTACT</h4>
                <h6 className="text-white">
                  <b>Email:</b>{" "}
                  <a href="mailto:contact@lifelabs.ro" className="text-white" style={{}}>
                    contact@lifelabs.ro
                  </a>
                </h6>
                {/* <h6 className="text-white">
                  <b>Facebook:</b> /lifelabs
                </h6> */}
                <Button
                  className="btn-icon-only rounded-circle"
                  color="instagram"
                  href="https://www.instagram.com/lifelabs.ro/"
                  id="tooltip475038074"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-instagram" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip475038074">
                  Follow us
                </UncontrolledTooltip>
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="facebook"
                  href="https://www.facebook.com/LifeLabsro-104834954821534"
                  id="tooltip837440414"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-facebook-square" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip837440414">
                  Like us
                </UncontrolledTooltip>
              </Col>
            </Row>
            <div className="d-flex align-items-center justify-content-center pt-4 pb-0">
              <div className="copyright text-white">
                © {new Date().getFullYear()} <span className="">LifeLabs</span>
              </div>
            </div>
          </Container>
        </footer>
      </>
    );
  }
}

export default SimpleFooter;
