import AuthModal from "components/Modals/AuthModal";
import { GlobalContext } from "context/GlobalState";
import React from "react";
import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
// reactstrap components
import { Button, UncontrolledCollapse, NavbarBrand, Navbar, NavItem, Nav, Container, Row, Col } from "reactstrap";

class HomeNavbar extends React.Component {
  static contextType = GlobalContext;

  componentDidMount() {
    // let headroom = new Headroom(document.getElementById("navbar-main"));
    // // initialise
    // headroom.init();
  }
  state = {
    authModal: null,

    collapseClasses: "",
    collapseOpen: false,
  };

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out",
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: "",
    });
  };

  render() {
    const { auth, signOut } = this.context;

    const NavItems = !auth ? (
      <>
        <NavItem className="ml-lg-4">
          <Button
            className="btn-radius btn-icon"
            outline
            color="warning"
            onClick={() => this.setState({ authModal: "signup" })}
          >
            <span className="nav-link-inner--text ml-1">Sign up</span>
          </Button>
        </NavItem>
        <NavItem className="ml-lg-4 mt-2 mt-lg-0">
          <Button
            className="btn-radius btn-icon"
            color="warning"
            onClick={() => this.setState({ authModal: "signin" })}
          >
            <span className="nav-link-inner--text ml-1">Log in</span>
          </Button>
        </NavItem>
      </>
    ) : (
      <>
        <NavItem className="ml-lg-4">
          <Button className="btn-radius" outline color="primary" onClick={signOut}>
            <span className="nav-link-inner--text ml-1">Log out</span>
          </Button>
        </NavItem>
        <NavItem className="ml-lg-4 mt-2 mt-lg-0">
          <img
            alt="..."
            src={auth.photoURL ? auth.photoURL : require("assets/img/theme/team-1-800x800.jpg")}
            className="img-fluid rounded-circle shadow auth-avatar"
            style={{ height: "46px" }}
          />
        </NavItem>
      </>
    );

    return (
      <>
        <header className="header-global">
          <Navbar className="navbar-main navbar-light" expand="lg" id="navbar-main">
            <Container>
              <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                <img alt="..." src={require("assets/img/brand/Logo_Life Labs-01.svg")} style={{ height: "42px" }} />
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={this.state.collapseClasses}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/">
                        <img
                          alt="..."
                          src={require("assets/img/brand/Logo_Life Labs-01.svg")}
                          style={{ height: "42px" }}
                        />
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>

                <Nav className="align-items-center ml-auto" navbar>
                  {NavItems}
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
        <AuthModal
          open={this.state.authModal !== null}
          toggle={() => this.setState({ authModal: null })}
          showSignIn={this.state.authModal === "signin"}
        />
      </>
    );
  }
}

export default HomeNavbar;
