import Api from "api/Api";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardImg, Container, Input } from "reactstrap";
import { storage } from "utils/firebase";
import { GlobalContext } from "context/GlobalState";
import ComponentLoader from "./ComponentLoader";
import download from "utils/download";
import { v4 as uuidv4 } from "uuid";
import Autosuggest from "react-autosuggest";

export default class Dashboard extends Component {
  static contextType = GlobalContext;

  hiddenFileInput = React.createRef();
  searchInput = React.createRef();

  constructor(props) {
    super();
  }

  state = {
    reports: [],
    suggestions: [],
    isLoading: true,

    searchFocus: false,
    searchValue: "",
  };

  async componentDidMount() {
    if (this.context.reports) {
      this.setState({ reports: this.context.reports, isLoading: false });
      return;
    }

    try {
      const snapshot = await Api.GetReports();
      const reports = [];
      snapshot.forEach((docRef) => {
        if (docRef.exists) {
          reports.push({ id: docRef.id, ...docRef.data() });
        }
      });
      this.context.setReports(reports);
      this.setState({ reports });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  removeReport = async (report) => {
    const { setLoader, setReports, reports } = this.context;
    try {
      setLoader({ isLoading: true, text: `Sterge analiza ${report.name}...` });
      await Api.RemoveReport(report);
      setReports(reports.filter((r) => r.id !== report.id));
      this.setState({ reports: this.context.reports });
    } catch (error) {
    } finally {
      setLoader({ isLoading: false });
    }
  };

  uploadFile = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    const file = e.target.files[0];
    const fileName = file.name;
    const filePath = `reports/${fileName}-${uuidv4()}`;
    const fileRef = storage.ref().child(filePath);

    const { setLoader, setReports, reports } = this.context;
    try {
      setLoader({ isLoading: true, text: `Incarca fisierul...` });
      await fileRef.put(file);
      const url = await fileRef.getDownloadURL();
      setLoader({ isLoading: true, text: "Analizeaza si creeaza raport..." });
      const report = await Api.CreateReport(file.name, filePath, url);
      setReports([...reports, report]);
      this.setState({ reports: [...reports, report] });
    } catch (error) {
    } finally {
      setLoader({ isLoading: false });
    }
  };

  search = async (e) => {
    const value = e.target.value.toLowerCase();
    this.setState({ searchValue: value });

    const { reports } = this.context;
    try {
      const results = [];
      reports.forEach((r) => {
        if (r.name.toLowerCase().includes(value)) results.push({ path: `/report/${r.id}`, report: r.name });

        if (!r.results) return;

        r.results.forEach((res) => {
          const roName = res.names["ro"].find((n) => n.toLowerCase().includes(value));
          const enName = res.names["en"].find((n) => n.toLowerCase().includes(value));

          if (roName) results.push({ path: `/report/${r.id}/result/${res.id}`, report: r.name, result: roName });

          if (enName) results.push({ path: `/report/${r.id}/result/${res.id}`, report: r.name, result: enName });
        });
      });
      return results.reduce((acc, crt) => {
        if (!acc.find((a) => a.path === crt.path)) {
          acc.push(crt);
        }
        return acc;
      }, []);
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  renderSuggestion = (suggestion) => {
    return (
      <div className="text-left text-black-50" onClick={() => this.props.history.push(suggestion.path)}>
        <b className="font-weight-bold">{suggestion.report}</b>
        {suggestion.result && <span> - {suggestion.result}</span>}
      </div>
    );
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue = (suggestion) =>
    suggestion.result ? `${suggestion.report} - ${suggestion.result}` : suggestion.report;

  // Use your imagination to render suggestions.

  renderSuggestionsContainer = ({ containerProps, children, query }) => {
    return <div {...containerProps}>{children}</div>;
  };
  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = async ({ value }) => {
    this.setState({
      suggestions: await this.search({ target: { value } }),
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  render() {
    const { reports, suggestions, isLoading, searchFocus, searchValue } = this.state;
    return (
      <>
        <section className="section">
          <Container className="d-flex flex-column align-items-center">
            <div className="w-100">
              <Button
                className="btn-icon btn-gradient-primary w-100 mr-0"
                color="default"
                onClick={() => this.hiddenFileInput.current.click()}
              >
                <div className="d-flex align-items-center justify-content-center">
                  <i className="ni ni-cloud-upload-96 ni-2x" />
                  <h3 className="display-4 text-white mb-0 font-weight-900">INCARCA ANALIZE</h3>
                </div>
              </Button>

              <input
                ref={this.hiddenFileInput}
                accept="application/pdf"
                type="file"
                hidden
                onChange={this.uploadFile}
              />

              <div className="position-relative" style={{ zIndex: 2 }}>
                <Button
                  className="btn-icon btn-outline-gradient-primary w-100 mt-3"
                  color="transparent"
                  outline
                  onClick={(e) => e.persist() || document.getElementById("search-input").focus()}
                  onFocus={() => this.setState({ searchFocus: true })}
                  onBlur={() => this.setState({ searchFocus: false })}
                >
                  <div className="btn-search d-flex align-items-center justify-content-center">
                    <i
                      className="fa fa-search fa-2x text-default cursor-pointer"
                      style={{ zIndex: searchFocus || searchValue ? 1 : 2 }}
                    />
                    <h3
                      className="display-4 mb-0 btn-inner-text text-default font-weight-900"
                      style={{ zIndex: searchFocus || searchValue ? 1 : 2 }}
                    >
                      CAUTA ANALIZE
                    </h3>
                  </div>
                  <Autosuggest
                    alwaysRenderSuggestions={true}
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    renderSuggestionsContainer={this.renderSuggestionsContainer}
                    inputProps={{
                      value: searchValue,
                      onChange: (e, { newValue }) => this.setState({ searchValue: newValue }),
                    }}
                    onSuggestionSelected={(e, { suggestion }) => this.props.history.push(suggestion.path)}
                    renderInputComponent={(inputProps) => (
                      <Input
                        id="search-input"
                        className="w-100"
                        ref={this.searchInput}
                        style={{ fontSize: "26px", zIndex: 1, width: "100.1%" }}
                        {...inputProps}
                      />
                    )}
                  />
                </Button>
              </div>
            </div>

            <div className="w-100 mt-4">
              {isLoading ? (
                <div className="mt-7 d-flex justify-content-center">
                  <ComponentLoader />
                </div>
              ) : (
                <>
                  {reports.length === 0 && (
                    <>
                      <h4 className="text-center font-weight-bold mt-3">Nicio analiza gasita 🩺</h4>

                      <Card
                        className="mt-6 text-center card-lift--hover shadow"
                        style={{ borderRadius: "12px", background: "rgba(255, 255, 255, 0.3)" }}
                      >
                        <CardBody>
                          <img alt="..." height={24} src={require("assets/img/theme/subtitle_icon.png")} />

                          <h1 className="display-2 text-default">Lorem Ipsum</h1>
                          <p className="mb-0 text-gray font-weight-500">
                            Lorem ipsum dolor sit amet, consecteta dusfie ane ie a heuibdaeb eawfhuih afehui h
                          </p>

                          <CardImg
                            alt="..."
                            className="mt-4"
                            height={400}
                            style={{ objectFit: "contain" }}
                            src={require("assets/img/theme/Website_Life Labs-08.png")}
                          />
                        </CardBody>
                      </Card>
                    </>
                  )}
                  <ul className="pl-0">
                    {reports.map((report) => (
                      <li
                        key={report.id}
                        className="d-flex justify-content-between pt-3 pb-3 border-bottom border-default"
                      >
                        <div className="d-flex align-items-center">
                          <img alt="..." src={require("assets/img/brand/Website_Life Labs-15.png")} height={28} />
                          <h5 className="font-weight-bolder mb-0 ml-3 text-primary">{report.name}</h5>
                        </div>

                        <div className="d-flex align-items-center">
                          <Link className="mr-4" to={`/report/${report.id}`}>
                            <Button className="btn-radius" color="default" outline>
                              Rezultate analiza
                            </Button>
                          </Link>

                          <Button
                            className="avatar bg-default"
                            color="default"
                            type="submit"
                            onClick={() => download(report.fileUrl, report.name)}
                          >
                            <i className="ni ni-cloud-download-95 ni-1-5x" />
                          </Button>

                          <Button
                            className="avatar bg-danger"
                            color="danger"
                            onClick={async () => await this.removeReport(report)}
                          >
                            <i className="fa fa-trash fa-1-5x" style={{ fontSize: "1.5em" }} />
                          </Button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </Container>
        </section>
      </>
    );
  }
}
