export const getResultStatus = (range, value) => {
  if (!range || !value) {
    return 0;
  }

  if (value < range[0] || value > range[1]) {
    return 0;
  }

  return 1;
};

export const getResultProcent = (range, value) => {
  let med = (range[1] - range[0]) / 2;
  let newRange = [range[0] - med, range[1] + med];
  let procent = ((value - newRange[0]) * 100) / (newRange[1] - newRange[0]);

  if (procent > 100) procent = 96;
  if (procent < 0) procent = 0;

  return procent;
};
