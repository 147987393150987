import React, { Component } from "react";
import SimpleFooter from "components/Footers/SimpleFooter";
import HomeNavbar from "components/Navbars/HomeNavbar";
import Hero from "views/IndexSections/Hero";
import { GlobalContext } from "context/GlobalState";
import Dashboard from "components/Dashboard";

export default class Home extends Component {
  static contextType = GlobalContext;

  render() {
    const { auth } = this.context;
    return (
      <>
        <HomeNavbar />
        <main ref="main">{!auth ? <Hero /> : <Dashboard history={this.props.history} />}</main>
        <SimpleFooter />
      </>
    );
  }
}
