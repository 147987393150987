import React from "react";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";

export default function Loader({ active, text, children }) {
  return (
    <LoadingOverlay active={active} text={text} spinner={<BounceLoader />}>
      {children}
    </LoadingOverlay>
  );
}
