import React from "react";

// reactstrap components
import { Container, Row, Col, CardImg } from "reactstrap";

class Hero extends React.Component {
  render() {
    return (
      <>
        {/* Hero for FREE version */}
        <section className="section">
          <Container className="px-0 d-flex align-items-center">
            <div className="col px-0">
              <Row className="align-items-center justify-content-center">
                <Col className="text-center" lg="5">
                  <div className="">
                    <div className="d-none d-lg-flex justify-content-between">
                      {"PORTOFOLIUL@TAU@DE".split("@").map((letter, i) => (
                        <h3 key={i} className="d-inline-block display-3 text-default font-weight-800 mb-0">
                          {letter}
                        </h3>
                      ))}
                    </div>
                    <div className="d-block d-lg-none">
                      <h3 className="text-default font-weight-800 mb-0">PORTOFOLIUL TAU DE</h3>
                    </div>

                    <h1
                      className="d-none d-sm-block display-1 text-default font-weight-bolder mb-0 extra-bolder"
                      style={{ fontSize: "6.2rem", lineHeight: "80px", textIndent: "-4px" }}
                    >
                      ANALIZE
                    </h1>
                    <h1
                      className=" d-block d-sm-none display-1 text-default font-weight-bolder mb-0 extra-bold"
                      style={{ fontSize: "4.2rem", lineHeight: "80px", textIndent: "-4px" }}
                    >
                      ANALIZE
                    </h1>

                    <br />
                    <br />
                    <br />
                    <img alt="..." height={24} src={require("assets/img/theme/subtitle_icon.png")} />
                    <div className="px-3">
                      <div className="d-flex justify-content-between">
                        {"LOREM IPSUM".split("").map((letter, i) => (
                          <h1 key={i} className="d-inline-block display-2 text-default text-left">
                            {letter}
                          </h1>
                        ))}
                      </div>
                      <p className="mb-0 text-gray text-left">
                        Lorem ipsum dolor sit amet, consecteta dusfie ane ie a heuibdaeb eawfhuih afehui h
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg="7">
                  <div className="d-none d-lg-block">
                    <CardImg
                      alt="..."
                      style={{ width: "130%" }}
                      src={require("assets/img/theme/Website_Life Labs-08.png")}
                    />
                  </div>

                  <div className="d-block d-lg-none">
                    <CardImg alt="..." className="mt-5" src={require("assets/img/theme/Website_Life Labs-08.png")} />
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
      </>
    );
  }
}

export default Hero;
