import Api from "api/Api";
import SimpleFooter from "components/Footers/SimpleFooter";
import HomeNavbar from "components/Navbars/HomeNavbar";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Container } from "reactstrap";
import download from "utils/download";
import ResultModal from "components/Modals/ResultModal";
import { GlobalContext } from "context/GlobalState";
import ComponentLoader from "components/ComponentLoader";
import { getResultStatus } from "utils/helpers";

export default class ReportPage extends Component {
  static contextType = GlobalContext;

  state = {
    report: null,
    isLoading: true,

    resultModal: false,
    selectedResult: null,
  };

  async componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    try {
      const docRef = await Api.GetReport(id);
      if (docRef.exists) {
        this.setState({ report: { id: docRef.id, ...docRef.data() } });
      }
    } catch (error) {
    } finally {
      this.setState({ isLoading: false });
    }
  }

  onSubmit = async (result) => {
    const { report, selectedResult } = this.state;
    const newReport = { ...report };
    if (selectedResult) {
      newReport.results = report.results.map((res) => {
        if (res.id === selectedResult.id) {
          var newResult = { ...res, ...result, name: result.names["ro"][0] };
          delete newResult.label;
          return newResult;
        }
        return res;
      });
    } else {
      var newResult = { ...result, name: result.names["ro"][0] };
      delete newResult.label;
      newReport.results.push(newResult);
    }

    try {
      await Api.UpdateReport(newReport);
      this.setState({ report: newReport, resultModal: false, selectedResult: null });
    } catch (error) {}
  };

  removeResult = async (result) => {
    const { report } = this.state;
    const newReport = {
      ...report,
      results: report.results.filter((r) => r.id !== result.id),
    };
    try {
      this.context.setLoader({ isLoading: true });
      await Api.UpdateReport(newReport);
      this.setState({ report: newReport });
    } catch (error) {
    } finally {
      this.context.setLoader({ isLoading: false });
    }
  };

  render() {
    const { isLoading, report, selectedResult, resultModal } = this.state;
    return (
      <>
        <HomeNavbar />
        <main ref="main">
          <section className="section">
            <Container className="d-flex flex-column align-items-center">
              <div className="d-flex flex-row justify-content-center w-100">
                <Button
                  className="btn-icon btn-gradient-primary"
                  color="default"
                  onClick={() => this.props.history.push("/")}
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <i className="ni ni-bold-left ni-2x" />
                    <h3 className="display-4 text-white mb-0 font-weight-900">LISTA ANALIZE</h3>
                  </div>
                </Button>

                <Button
                  className="btn-icon btn-outline-gradient-primary ml-5"
                  color="transparent"
                  outline
                  onClick={() => download(report.fileUrl, report.name)}
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <i className="ni ni-cloud-download-95 ni-2x text-default" style={{ zIndex: 2 }} />
                    <h3 className="display-4 mb-0 btn-inner-text text-default font-weight-900">DESCARCA ANALIZA</h3>
                  </div>
                </Button>
              </div>
              {isLoading ? (
                <div className="mt-7">
                  <ComponentLoader />
                </div>
              ) : (
                report && (
                  <div className="mt-5 w-100">
                    <div className="d-flex align-items-center justify-content-center">
                      <img alt="..." src={require("assets/img/brand/Website_Life Labs-15.png")} height={28} />
                      <h5 className="font-weight-bolder mb-0 ml-2 text-primary">{report.name}</h5>
                    </div>

                    {report.results && report.results.length === 0 && (
                      <h4 className="text-center font-weight-bold mt-3">Niciun rezultat gasit</h4>
                    )}
                    <table className="mt-5 w-100" style={{ whiteSpace: "nowrap" }}>
                      {report.results &&
                        report.results.map((result) => {
                          const range = result && result.range && result.range[result.unit];
                          const status = result && getResultStatus(range, result.value);

                          return (
                            <tr key={result.id} className="border-bottom border-default">
                              <td>
                                <span className="text-primary" style={{ whiteSpace: "nowrap" }}>
                                  <b>{result.name}</b>
                                </span>
                              </td>

                              <td>
                                <span className="text-primary">
                                  <b>{result.value}</b>
                                </span>
                              </td>

                              <td className="text-center">
                                <span className="text-primary font-weight-600">{result.unit}</span>
                              </td>

                              <td className="text-center">
                                {status < 0 && (
                                  <img alt="..." src={require("assets/img/theme/status-bad.png")} height={28} />
                                )}
                                {status === 0 && (
                                  <img alt="..." src={require("assets/img/theme/status-medium.png")} height={28} />
                                )}
                                {status > 0 && (
                                  <img alt="..." src={require("assets/img/theme/status-good.png")} height={28} />
                                )}
                              </td>

                              <td className="pr-4" style={{ width: "1%" }}>
                                <Link to={`/report/${report.id}/result/${result.id}`}>
                                  <Button className="btn-radius" color="default" outline>
                                    Detalii analiza
                                  </Button>
                                </Link>
                              </td>
                              <td className="text-right pr-2" style={{ width: "1%" }}>
                                <Button
                                  className="avatar bg-default"
                                  color="default"
                                  onClick={() => this.setState({ resultModal: true, selectedResult: result })}
                                >
                                  <i className="fa fa-pencil" style={{ fontSize: "1.5em" }} />
                                </Button>
                              </td>
                              <td className="text-right" style={{ width: "1%" }}>
                                <Button
                                  className="avatar bg-danger"
                                  color="danger"
                                  onClick={() => this.removeResult(result)}
                                >
                                  <i className="fa fa-trash" style={{ fontSize: "1.5em" }} />
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                    </table>

                    <div className="text-center mt-5 mb-3" style={{ zIndex: "10" }}>
                      <Button
                        className="btn-radius"
                        color="primary"
                        onClick={() => this.setState({ resultModal: true })}
                      >
                        Adauga analiza
                      </Button>
                    </div>
                  </div>
                )
              )}
            </Container>
          </section>

          <ResultModal
            open={resultModal}
            toggle={() => this.setState({ resultModal: false, selectedResult: null })}
            submit={this.onSubmit}
            result={selectedResult}
          />
        </main>
        <SimpleFooter />
      </>
    );
  }
}
