import Api from "api/Api";
import { auth } from "../utils/firebase";
import { GlobalActionType } from "./GlobalState";

export default (state, action) => {
  switch (action.type) {
    case GlobalActionType.SET_AUTH:
      Api.SetAuth(action.payload);
      return { ...state, auth: action.payload };

    case GlobalActionType.SET_REPORTS:
      return { ...state, reports: action.payload };

    case GlobalActionType.SET_LOADER:
      return { ...state, loader: action.payload };

    case GlobalActionType.SIGN_OUT:
      auth().signOut();
      return { ...state, reports: null, auth: null };

    default:
      break;
  }
};
