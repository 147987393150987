import React from "react";
import ReactSlider from "react-slider";
import styled from "styled-components";

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 16px;
`;

const StyledThumb = styled.div`
  height: 28px;
  line-height: 25px;
  width: 28px;
  margin-top: -6px;
  text-align: center;
  background-color: #fff;
  color: #fff;
  border-radius: 50%;
  border: 0.1px solid rgba(0, 0, 0, 0.18);
  z-index: 2;
`;

const StyledSeparator = styled.div`
  height: 28px;
  width: 4px;
  margin-top: -6px;
  text-align: center;
  background-color: #fff;
  z-index: 1;
`;

const Thumb = (props, state) => {
  if (
    (state.valueNow !== 25 || (state.value[0] === state.value[1] && state.index === 0)) &&
    (state.valueNow !== 75 || (state.value[1] === state.value[2] && state.index === 1))
  ) {
    return <StyledThumb id="slider-thumb" {...props} />;
  } else {
    return <StyledSeparator id="slider-separator" {...props} />;
  }
};

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${(props) => (props.index >= props.startIndex && props.index <= props.endIndex ? "#4FCE05" : "#F7C341")};
  border-top-left-radius: ${(props) => (props.index < 1 ? "999px" : "0px")};
  border-bottom-left-radius: ${(props) => (props.index < 1 ? "999px" : "0px")};
  border-top-right-radius: ${(props) => (props.index > 2 ? "999px" : "0px")};
  border-bottom-right-radius: ${(props) => (props.index > 2 ? "999px" : "0px")};
`;

const Track = (props, state) => {
  let startIndex = 1;
  let endIndex = 2;
  if (state.value[0] < 25) {
    startIndex = 2;
    endIndex = 2;
  }
  if (state.value[2] > 75) {
    startIndex = 1;
    endIndex = 1;
  }

  return <StyledTrack {...props} index={state.index} startIndex={startIndex} endIndex={endIndex} />;
};

const SliderWrapper = styled.div`
  width: 55%;
  border: 16px solid #eaebf4;
  border-radius: 24px;
`;

export default function ({ value, disabled, status }) {
  return (
    <SliderWrapper>
      <StyledSlider
        defaultValue={[25, value, 75].sort((a, b) => a - b)}
        disabled={disabled}
        renderTrack={(props, state) => Track({ ...props, status }, state)}
        renderThumb={Thumb}
      />
    </SliderWrapper>
  );
}
