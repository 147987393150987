import Axios from "axios";
import { storage } from "utils/firebase";
import { db } from "utils/firebase";

const baseURL = "https://europe-west3-labs-scanner.cloudfunctions.net";

export default class Api {
  static _auth = null;

  static SetAuth(auth) {
    this._auth = auth;
  }

  static async GetReports() {
    return await db.collection("reports").where("userId", "==", this._auth.uid).orderBy("creationTime").get();
  }

  static async GetReport(id) {
    return await db.collection("reports").doc(id).get();
  }

  static async SearchReports(name) {
    // return await db.collection("reports").where("searchName", "array-contains", name.toLowerCase()).get();
  }

  static async CreateReport(name, filePath, fileUrl) {
    const params = {
      filePath,
    };
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    };
    const {
      data: { result },
    } = await Axios.get(`${baseURL}/parsePDF`, { headers, params });

    // TODO: change
    const results = result.map((res) => {
      const name = res.names["ro"][0];
      const r = { ...res, name, id: res.id.replace("%", "Percent") };
      delete r.name_found;
      delete r.unit_found;

      return r;
    });
    const report = {
      name,
      filePath,
      fileUrl,
      results,
      userId: this._auth.uid,
      creationTime: Date.now(),
    };
    const docRef = await db.collection("reports").add(report);
    return { id: docRef.id, ...report };
  }

  static async RemoveReport(report) {
    await db.collection("reports").doc(report.id).delete();
    await storage.ref().child(report.filePath).delete();
  }

  static async UpdateReport(report) {
    await db.collection("reports").doc(report.id).set(report);
  }

  static async GetMarkers() {
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    };
    const { data } = await Axios.get(`${baseURL}/markers`, { headers });
    return data;
  }
}
