import React, { useEffect, useState } from "react";
import { Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal, ModalBody } from "reactstrap";
import AsyncSelect from "react-select/async";
import Button from "reactstrap-button-loader";
import Api from "api/Api";

const ResultModal = ({ open, toggle, result, submit }) => {
  const [currentResult, setCurrentResult] = useState(result);
  const [value, setValue] = useState(result?.value);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);
    if (result) {
      setCurrentResult({ ...result, label: result?.name });
    } else {
      setCurrentResult(null);
    }
    setValue(result ? result.value : "");
  }, [open, result]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (!currentResult) {
      return;
    }

    setIsLoading(true);
    submit({ ...currentResult, value });
  };

  const getMarkersAsync = async (inputValue) => {
    const markers = await Api.GetMarkers();
    return markers
      .filter((m) => m.names["ro"][0].toLowerCase().includes(inputValue.toLowerCase()))
      .map((m) => {
        return { label: m.names["ro"][0], value: m.id, ...m };
      });
  };

  return (
    <Modal isOpen={open} toggle={toggle} className="modal-dialog-centered">
      <ModalBody>
        <h4 className="text-center font-weight-bold mb-2">{result ? "Editeaza analiza" : "Adauga analiza"}</h4>
        <Form role="form" onSubmit={onSubmit} className="mt-3">
          <FormGroup className="mb-3">
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText className={result ? "form-control-disabled" : ""}>
                  <i className="ni ni-archive-2" />
                </InputGroupText>
              </InputGroupAddon>
              {/* <Input
                required
                placeholder="Name"
                type="text"
                className="font-weight-bold"
                autoComplete="new-text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              /> */}
              <div style={{ width: "91.57%" }}>
                <AsyncSelect
                  isDisabled={result !== null}
                  loadOptions={getMarkersAsync}
                  cacheOptions
                  defaultOptions
                  value={currentResult}
                  onChange={(val) => setCurrentResult({ ...val, unit: val.units[0] })}
                  placeholder="Selecteaza analiza..."
                  styles={{
                    container: (base) => ({
                      ...base,
                    }),
                    control: (base) => ({
                      ...base,
                      border: "0px",
                    }),
                    valueContainer: (base) => ({
                      ...base,
                      paddingLeft: "2px",
                      fontWeight: "600",
                    }),
                    singleValue: (base) => ({
                      ...base,
                      color: "#8898aa",
                    }),
                    placeholder: (base) => ({
                      ...base,
                      color: "#8898aa",
                      // fontSize: inher,
                      // fontWeight: "bold",
                    }),
                  }}
                />
              </div>
            </InputGroup>
          </FormGroup>

          <FormGroup className="mb-3">
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-chart-pie-35" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                required
                placeholder="Value"
                type="number"
                step={0.1}
                style={{
                  fontFamily: '"Quicksand", sans-serif',
                  fontWeight: "600",
                  fontSize: "1em",
                  paddingLeft: "4px",
                }}
                // className="font-weight-bold"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
              {currentResult?.unit && (
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <b>{currentResult.unit}</b>
                  </InputGroupText>
                </InputGroupAddon>
              )}
            </InputGroup>
          </FormGroup>

          <div className="text-center">
            <Button
              loading={isLoading}
              disabled={!currentResult || !value}
              className="my-4 btn-radius"
              color="primary"
              type="submit"
            >
              Salveaza
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ResultModal;
