import { auth } from "../firebase";

export function signup(user) {
  return auth()
    .createUserWithEmailAndPassword(user.email, user.password)
    .then(({ user }) => {
      user.updateProfile({ displayName: user.displayName });
    });
}

export function signin(user) {
  return auth().signInWithEmailAndPassword(user.email, user.password);
}

export function signInWithGoogle() {
  const provider = new auth.GoogleAuthProvider();
  return auth().signInWithPopup(provider);
}

export function signInWithFacebook() {
  const provider = new auth.FacebookAuthProvider();
  return auth().signInWithPopup(provider);
}
