import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { auth } from "utils/firebase";
import { GlobalContext } from "context/GlobalState";

import Home from "pages/Home";
import ReportPage from "pages/ReportPage";
import ResultPage from "pages/ResultPage";
import Loader from "components/Loader";

export default class App extends Component {
  static contextType = GlobalContext;

  componentDidMount() {
    const { setAuth, setLoader } = this.context;
    auth().onAuthStateChanged((user) => {
      if (user) {
        setAuth(user);
      } else {
        setAuth(null);
      }
      setLoader({ isLoading: false });
    });
  }

  render() {
    const { auth, loader } = this.context;

    return (
      <Loader active={this.context.loader.isLoading} text={this.context.loader.text}>
        {/* {!loader.isLoading ? ( */}
        <BrowserRouter>
          <Switch>
            <Route path="/" exact render={(props) => <Home {...props} />} />
            {auth && <Route path="/report/:id" exact render={(props) => <ReportPage {...props} />} />}
            {auth && (
              <Route path="/report/:reportId/result/:resultId" exact render={(props) => <ResultPage {...props} />} />
            )}
            <Redirect to="/" />
          </Switch>
        </BrowserRouter>
      </Loader>
    );
  }
}
