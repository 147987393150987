import React, { useState } from "react";
import { useEffect } from "react";
import {
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import Button from "reactstrap-button-loader";
import { signup, signin, signInWithFacebook, signInWithGoogle } from "utils/auth/authFirebase";

const AuthModal = ({ open, toggle, showSignIn }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});

  const [isSignIn, setIsSignIn] = useState(showSignIn);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsSignIn(showSignIn);
  }, [showSignIn]);

  const onSubmit = async (e) => {
    e.preventDefault();

    let errors = {};
    if (!password || password.length < 6) {
      errors["password"] = "Password must be at least 6 characters";
    }

    setErrors(errors);
    if (Object.keys(errors).length > 0) {
      return;
    }

    try {
      setIsLoading(true);
      if (isSignIn) {
        await signin(email, password);
      } else {
        await signup({ displayName: name, email, password });
      }
    } catch ({ code, message }) {
      setErrors({ ...errors, [code]: message });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={open} toggle={toggle} className="">
      <ModalBody>
        <h3 className="display-4 text-center">{isSignIn ? "Log in" : "Sign up"}</h3>

        <Row className="py-3">
          <Col>
            <div className="text-sm-right text-center pb-2">
              <Button
                className="btn-neutral btn-icon"
                color="default"
                onClick={() =>
                  signInWithFacebook()
                    .then(() => toggle())
                    .catch((err) => setErrors({ err: err.message }))
                }
              >
                <span className="btn-inner--icon">
                  <img alt="..." src={require("assets/img/icons/common/facebook.png")} />
                </span>
                <span className="btn-inner--text">Facebook</span>
              </Button>
            </div>
          </Col>

          <Col>
            <div className="text-sm-left text-center pb-2">
              <Button
                className="btn-neutral btn-icon"
                color="default"
                onClick={() =>
                  signInWithGoogle()
                    .then(() => toggle())
                    .catch((err) => setErrors({ err: err.message }))
                }
              >
                <span className="btn-inner--icon">
                  <img alt="..." src={require("assets/img/icons/common/google.svg")} />
                </span>
                <span className="btn-inner--text">Google</span>
              </Button>
            </div>
          </Col>
        </Row>

        <div className="signup-or">
          <span className="signup-or-text">Or</span>
        </div>

        <Form role="form" onSubmit={onSubmit} className="mt-0">
          {!isSignIn && (
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-user-run" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  required
                  placeholder="Name"
                  type="text"
                  autoComplete="new-text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </InputGroup>
            </FormGroup>
          )}

          <FormGroup className="mb-3">
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-email-83" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                required
                placeholder="Email"
                type="email"
                autoComplete="new-email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-lock-circle-open" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                required
                placeholder="Password"
                type="password"
                autoComplete="new-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </InputGroup>
          </FormGroup>
          <div className="text-center">
            <Button loading={isLoading} className="my-4 btn-radius" color="primary" type="submit">
              {isSignIn ? "Sign in" : "Sign up"}
            </Button>
          </div>
          {isSignIn ? (
            <p className="signup-already">
              Don't have an account?{" "}
              <span onClick={() => setIsSignIn(false)} className="signup-already-link">
                Sign up
              </span>
            </p>
          ) : (
            <p className="signup-already">
              Already have an account?{" "}
              <span onClick={() => setIsSignIn(true)} className="signup-already-link">
                Login
              </span>
            </p>
          )}
        </Form>

        {Object.keys(errors).length > 0 &&
          Object.keys(errors).map((key) => (
            <div key={key} className="d-flex justify-content-center pt-1 mb-0">
              <p className="mt-0 mb-0 text-danger text-center">{errors[key]}</p>
            </div>
          ))}
      </ModalBody>
    </Modal>
  );
};

export default AuthModal;
