import React, { Component } from "react";
import Api from "api/Api";
import ComponentLoader from "components/ComponentLoader";
import SimpleFooter from "components/Footers/SimpleFooter";
import HomeNavbar from "components/Navbars/HomeNavbar";
import StyledSlider from "components/StyledSlider";
import { GlobalContext } from "context/GlobalState";
import { Button, Card, CardBody, Container } from "reactstrap";
import { getResultStatus } from "utils/helpers";
// react plugin used to create charts
import { Chart, Bar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
// core components
import { chartOptions, parseOptions } from "variables/charts.js";
import { getResultProcent } from "utils/helpers";

const data = {
  labels: [],
  datasets: [
    {
      data: [],
      backgroundColor: "rgba(255, 255, 255, 1)",
      borderWidth: 1,
      maxBarThickness: 50,
    },
  ],
};

const options = {
  plugins: {
    datalabels: {
      align: "top",
      display: true,
      color: "white",
      anchor: "end",
      font: {
        weight: "bold",
      },
      clamp: true,
    },
  },
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 30,
      bottom: 0,
    },
  },
  scales: {
    xAxes: [
      {
        ticks: {
          fontColor: "white",
          //   fontSize: 14,
          fontStyle: "bold",
          stepSize: 1,
          beginAtZero: true,
        },
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          callback: function (value) {},
          fontColor: "white",
          stepSize: 1,
          beginAtZero: true,
        },
        gridLines: {
          display: false,
        },
        tooltips: {
          callbacks: {
            label: function (item, data) {
              var label = data.datasets[item.datasetIndex].label || "";
              var yLabel = item.yLabel;
              var content = "";
              if (data.datasets.length > 1) {
                content += label;
              }
              content += yLabel;
              return content;
            },
          },
        },
      },
    ],
  },
  legend: {
    display: false,
  },
};

export default class ResultPage extends Component {
  static contextType = GlobalContext;

  state = {
    report: null,
    result: null,
    isLoading: true,
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  async componentDidMount() {
    const {
      match: {
        params: { resultId, reportId },
      },
    } = this.props;
    try {
      const docRef = await Api.GetReport(reportId);
      if (docRef.exists) {
        const report = { id: docRef.id, ...docRef.data() };
        this.setState({
          report,
          result: report.results.find((r) => r.id === resultId),
        });
      }
    } catch (error) {
    } finally {
      this.setState({ isLoading: false });
    }
  }

  setChartData = (result, reports) => {
    if (!result) {
      return [];
    }

    const values = [];
    reports.forEach((rep) => {
      var res = rep.results.find((r) => r.id === result.id);
      if (res) {
        values.push({ value: res.value, date: rep.creationTime });
      }
    });

    data.datasets[0].data = values.map((v) => v.value);
    data.labels = values.map((v) => new Date(v.date).toLocaleString("default", { month: "long", year: "numeric" }));
    return values;
  };

  render() {
    const { isLoading, report, result } = this.state;
    const { reports } = this.context;

    this.setChartData(result, reports);

    const range = result && result.range[result.unit];
    const procent = range && getResultProcent(range, result.value);
    const status = result && getResultStatus(range, result.value);
    return (
      <>
        <HomeNavbar />
        <main ref="main">
          <section className="section">
            <Container className="d-flex flex-column align-items-center">
              <div className="d-flex flex-row justify-content-center w-100">
                <Button
                  className="btn-icon btn-gradient-primary"
                  color="default"
                  onClick={() => this.props.history.push(`/report/${report.id}`)}
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <i className="ni ni-bold-left ni-2x" />
                    <h3 className="display-4 text-white mb-0 font-weight-900">REZULTATE</h3>
                  </div>
                </Button>

                <Button
                  className="btn-icon btn-outline-gradient-primary ml-5"
                  color="transparent"
                  outline
                  onClick={() => this.props.history.push(`/`)}
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <i className="ni ni-bold-left ni-2x text-default" style={{ zIndex: 2 }} />
                    <h3 className="display-4 mb-0 btn-inner-text text-default font-weight-900">LISTA ANALIZE</h3>
                  </div>
                </Button>
              </div>
              {isLoading ? (
                <div className="mt-7 text-center">
                  <ComponentLoader />
                </div>
              ) : (
                result && (
                  <div className="mt-5 w-100">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <img alt="..." src={require("assets/img/brand/Website_Life Labs-15.png")} height={28} />
                      <h5 className="font-weight-bolder mb-0 ml-2 text-primary">{result.name}</h5>

                      {status < 0 && (
                        <img className="mt-4" alt="..." src={require("assets/img/theme/status-bad.png")} height={36} />
                      )}
                      {status === 0 && (
                        <img
                          className="mt-4"
                          alt="..."
                          src={require("assets/img/theme/status-medium.png")}
                          height={36}
                        />
                      )}
                      {status > 0 && (
                        <img className="mt-4" alt="..." src={require("assets/img/theme/status-good.png")} height={36} />
                      )}

                      <div className="d-flex justify-content-between w-50 mt-6 mb-2 position-relative">
                        <span>
                          <b>Valoare:</b>
                          <b className="ml-2">{result.value}</b>
                        </span>
                        <b>{result.unit}</b>
                      </div>

                      <StyledSlider value={procent} status={status} disabled={true} />

                      <div className="text-center mt-3">
                        {range && <b>{`${range[0]} - ${range[1]}`}</b>}
                        <p>(Val. recomandata)</p>
                      </div>

                      <p className="mt-8 text-center font-weight-500">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                      </p>
                    </div>
                  </div>
                )
              )}
            </Container>

            {!isLoading && (
              <div className="chart py-6 mt-7 mb-5 bg-gradient-default" style={{ minHeight: "550px" }}>
                {/* Chart wrapper */}
                <Container className="w-100 h-100" style={{ minHeight: "400px", zIndex: 2 }}>
                  <Bar data={data} options={options} />
                </Container>
              </div>
            )}
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}
